<template>
  <div class="login-content">
    <div
        style="width: 520px;text-align: center;position: relative;">
      <div style="position: relative;">
        <img src="../../assets/login_logo.png" style="width: 294px;height: auto;">
        <p style="margin-left: 20px;color: #092E7F;font-size: 56px;font-family: AlibabaPuHuiTi-2-85-Bold;display: inline-block">
          OG</p>
      </div>
      <input class="input-container" v-model="email" type="email" placeholder="Account">
      <input class="input-container" style="margin-top: 40px" v-model="pwd" type="password" placeholder="Password">

      <el-select class="login-select" :popper-append-to-body="false" v-model="login_type"
                 placeholder="Identify Type" style="margin-top: 4.4vh">
        <el-option label="Student" value="student"></el-option>
        <el-option label="Adviser" value="contact"></el-option>
      </el-select>

      <div class="btn-login" @click="doLogin">Login</div>
    </div>
  </div>
</template>

<script>
import '../../assets/common/font.css'
import {loginByStudent,loginByContact} from "../../api/fbla";
import {clearUser, setUserId, setUserName,setUserType} from "../../utils/store";
//测试 学生：123ceshi@qq.com impact， 老师：123ceshi-adviser@qq.com,service
export default {
  name: "Login",
  data() {
    return {
      login_type: '',
      email: '',
      pwd: ''
    }
  },
  mounted() {
    clearUser()
  },
  methods: {

    doLogin() {
      if (this.email == '') {
        this.$message.error('Please input account')
        return
      }
      if (this.pwd == '') {
        this.$message.error('Please input password')
        return
      }
      if (this.login_type == '') {
        this.$message.error('Please select identify type')
        return;
      }
      if (this.login_type == 'student') {
        loginByStudent(this.email, this.pwd).then((res) => {
          let result = res.data
          if (result.code == 0) {
            let user_id = result.data._id.$id
            let user_name = result.data.student_lastName + result.data.student_givenName
            setUserType('student')
            setUserId(user_id)
            setUserName(user_name)
            this.$UserData.user_name = user_name
            this.$router.push('/')
          } else {
            this.$message.error(result.msg)
          }
        })
      } else {
        loginByContact(this.email, this.pwd).then((res) => {
          let result = res.data
          if (result.code == 0) {
            let user_id = result.data._id.$id
            let user_name = result.data.contact_lastName + result.data.contact_givenName
            setUserType('contact')
            setUserId(user_id)
            setUserName(user_name)
            this.$UserData.user_name = user_name
            this.$router.push('/')
          } else {
            this.$message.error(result.msg)
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">

.btn-login {
  margin-top: 50px;
  width: 100%;
  cursor: pointer;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 30px;
  box-sizing: border-box;
  font-size: 20px;
  font-family: AlibabaPuHuiTi-2-85-Bold;
  font-weight: normal;
  color: #FFFFFF;
  background: #092E7F;
}

.input-container {
  margin-top: 60px;
  padding: 14px 30px;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.06);
  outline: none;
  border: none;
  font-size: 18px;
  font-family: AlibabaPuHuiTi-2-55-Regular;
  font-weight: normal;
  color: #333333;
}

.input-container::placeholder {
  color: #999999;
}

.login-content {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/login_bg.png");
}


/deep/ .el-input__inner {
  padding: 14px 30px;
  box-sizing: border-box;
  width: 520px;
  height: 60px;
  border-radius: 30px;
  box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.06);
  outline: none;
  border: none;
  font-size: 18px;
  font-family: AlibabaPuHuiTi-2-55-Regular;
  font-weight: normal;
  color: #333333;
}

/deep/ .el-input__inner::placeholder {
  font-size: 18px;
  font-family: AlibabaPuHuiTi-2-55-Regular;
  color: #999999;
}

/deep/ .el-select-dropdown__item {
  color: #333333;
  font-size: 18px;
  height: 60px;
  line-height: 60px
}

</style>
